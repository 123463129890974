import { useEffect } from "react";
import "./App.css";
import Head from "./Header";
function Refund() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* <Head /> */}
      <div className="container min-container" style={{ marginTop: 150 }}>
        <div className="row">
          <div className="col-12">
            <h1 className="policy-head">Return and Refund Policy</h1>
            <h4 className="policy-para">1. Introduction</h4>
            <p className="policy-para">
              This Return and Refund Policy ("Policy") explains how Mannschaft
              Software Services LLP ("Company") handles returns and refunds for
              items purchased on our Website/mobile app, www.eatprotein.in.
              Please review this Policy carefully before placing your order.
            </p>
            <h4 className="policy-para">2. Return Eligibility</h4>
            <p className="policy-para">
              You may return except restaurant category items purchased on our
              Website/mobile app within 1hr time of your purchase date and time.
              Items must be:
            </p>
            <ol className="terms policy-para">
              <li>
                In their original condition, with all tags and packaging
                attached.
              </li>
              <li>Unused and undamaged.</li>

              <li>
                Accompanied by a copy of your receipt or proof of purchase.
              </li>
            </ol>

            <div className="policy-para">
              <h4>3. Exceptions to Return Policy</h4>
              <p>The following items are not eligible for return:</p>
              <ol className="terms policy-para">
                <li>Restaurant Items </li>
                <li>Items returned after the1 hr time return window.</li>
                <li>Items damaged or used by the customer.</li>
              </ol>
            </div>
            <div className="policy-para">
              <h4>4. Return Process</h4>
              <p>To initiate a return, please follow these steps:</p>
              <ol className="terms policy-para">
                <li>
                  Visit our Website/mobile app and navigate to the "Returns"
                  page.{" "}
                </li>
                <li>
                  Go to your account orders history, select the order and click
                  the return button
                </li>
                <li>
                  Select the items you wish to return and the reason for your
                  return.
                </li>
                <li>
                  With in 6 hrs our team will contact you and assist the process{" "}
                </li>
              </ol>
            </div>
            <div className="policy-para">
              <h4>5. Refunds</h4>
              <p>
                Once we receive and inspect the returned item, we will process
                your refund within [ 7 business days. Refunds will be issued to
                the original payment method used for the purchase.
              </p>
              <ol className="terms policy-para">
                <li>
                  <b>Standard shipping costs:</b> We will refund standard
                  shipping costs if the return is due to our error or a
                  defective product.{" "}
                </li>
                <li>
                  <b>Return shipping costs:</b> You will be responsible for
                  return shipping costs unless the return is due to our error or
                  a defective product.
                </li>
                <li>
                  <b>Sale items:</b> Sale items may be subject to a different
                  return and refund policy, as specified on the product page.
                </li>
              </ol>
            </div>
            <div className="policy-para">
              <h4>6. Disputes</h4>
              <p>
                If you are not satisfied with your return or refund, please
                contact our customer service team at support@eatprotein.in or
                +91 7036356760. We will try our best to resolve the issue to
                your satisfaction.
              </p>
            </div>
            <div className="policy-para">
              <h4>7. Changes to Return and Refund Policy</h4>
              <p>
                We reserve the right to change this Policy at any time. We will
                post any changes on our Website/mobile app.
              </p>
            </div>
            <div className="policy-para">
              <h4>9. Contact Information</h4>
              <p>
                If you have any questions about this Return and Refund Policy,
                please contact us at support@eatprotein.in
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Refund;
