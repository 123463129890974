import { useNavigate } from "react-router-dom";

function ReferAndSave() {
  const navigate = useNavigate();
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <h1 className="about-head col-12 mb-5 w-100">Refer A Friend</h1>

          <div
            className="col-12 col-lg-6 d-block d-flex flex-row d-lg-none"
            style={{ backgroundColor: "#77eb34" }}
          >
            <div className="d-flex flex-row justify-content-center">
              <img
                src={"/refer step1.png"}
                className=""
                style={{ width: "100%" }}
                alt=""
              />
            </div>
            <div className="d-flex flex-row justify-content-center">
              <img
                src={"/color refer.png"}
                className=""
                style={{ width: "100%" }}
                alt=""
              />
            </div>
          </div>
          <div
            className="col-12 col-lg-6 d-block d-lg-none mb-3"
            style={{ backgroundColor: "#77eb34" }}
          >
            <div className=" d-flex flex-row justify-content-center">
              <img
                src={"/refer emoji.png"}
                className=""
                style={{ width: "70%" }}
                alt=""
              />
            </div>
          </div>
          <div
            className="col-12 w-100  d-none d-lg-block"
            style={{ backgroundColor: "#77eb34" }}
          >
            <div className="refer-image2 min-container2 w-100">
              <div className="d-flex flex-row w-100">
                <div className=" w-50">
                  <img
                    src={"/refer step1.png"}
                    className=""
                    style={{ width: "100%", marginRight: 50 }}
                    alt=""
                  />
                </div>
                <div className="w-50">
                  <img
                    src={"/color refer.png"}
                    className=""
                    style={{ width: "100%", marginRight: 50 }}
                    alt=""
                  />
                </div>
                <div className="w-50">
                  <img
                    src={"/refer emoji.png"}
                    className=""
                    style={{ width: "100%" }}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-row justify-content-center">
            <div className=" col-lg-4 col-sm-12 col-xs-12  d-flex flex-row justify-content-center">
              <a className="btn btn-danger mt-4 mb-2 " href="#download">
                Refer & Save
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ReferAndSave;
