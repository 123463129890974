import { useNavigate } from "react-router-dom";

function Franchisee() {
  const navigate = useNavigate();

  return (
    <>
      <div className="container-fluid container">
        <div className="row">
          <h1 className="about-head col-12" style={{ marginTop: 50 }}>
            Franchise Opportunity
          </h1>

          <div className="col-12 col-lg-6 d-flex flex-column justify-content-center">
            <div className="image-container min-container">
              <img
                src={"/service.png"}
                style={{ width: "75%", height: "100%" }}
                className="aboutus-image shadow-lg aboutUs-image2"
                alt=""
              />
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="text-container min-container">
              <p className="para-text-size">
                "Eat Protein" in Your Area Looking to earn extra income with
                minimal investment and effort? Join us with the "Eat Protein"
                mobile app franchise, a platform offering detailed protein
                nutrition info for various food categories and location-based
                store listings.
                <br />
                <h1 style={{ fontWeight: "bold", marginTop: 20, fontSize: 27 }}>
                  Why Choose "Eat Protein"?
                </h1>
                <span style={{ fontWeight: "bold", fontSize: 25 }}>
                  Low Investment, High Return:
                </span>
                <span style={{ fontSize: 21 }}>
                  {" "}
                  Start with a small investment and enjoy a profitable business.
                </span>
                <br />
                <span style={{ fontWeight: "bold", fontSize: 25 }}>
                  Effortless Income:
                </span>
                <span style={{ fontSize: 21 }}>
                  {" "}
                  Earn steady income with minimal effort.
                </span>
                <br />
                <span style={{ fontWeight: "bold", fontSize: 25 }}>
                  {" "}
                  No Career Disruption:{" "}
                </span>
                <span style={{ fontSize: 21 }}>
                  Continue your current job while benefiting from this passive
                  income.
                </span>
                <br />
                <span style={{ fontWeight: "bold", fontSize: 25 }}>
                  {" "}
                  Ready-to-Launch:{" "}
                </span>
                <span style={{ fontSize: 21 }}>
                  Get a fully developed app, ready for immediate business.
                </span>
                <br />
              </p>
              <button
                className="about-button col-4 franchise-button-width"
                onClick={() => navigate("/sconetent")}
              >
                Read More
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Franchisee;
