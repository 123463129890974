import "./App.css";
import { Link } from "react-router-dom";
function Download() {
  const handleAndroidDownload = () => {
    // URL to the app file
    const appFileUrl =
      "https://play.google.com/store/apps/details?id=com.mannschaft.eatprotien";
    const link = document.createElement("a");
    link.href = appFileUrl;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleIosDownload = () => {
    // URL to the app file
    const appFileUrl = "https://apps.apple.com/in/app/eat-protein/id6636532383";
    const link = document.createElement("a");
    link.href = appFileUrl;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <>
      <div className="container-fluid container">
        <div className="row">
          <h1 className="about-head col-12" id="download">
            Download App
          </h1>

          <div className="col-12 col-lg-5 d-flex flex-row justify-content-center">
            <div className="image-container min-container d-flex flex-column w-100 h-100">
              <div className="mt-2 w-100 h-100 d-flex flex-row justify-content-center">
                <img
                  src="/why protein.png"
                  className="service-height"
                  style={{ borderRadius: 10 }}
                />
              </div>

              <div className="d-flex flex-row justify-content-center w-100 h-100">
                <img
                  src={"/android download.png"}
                  className="android-download"
                  onClick={handleAndroidDownload}
                  alt=""
                />

                <img
                  src={"/download.jpeg"}
                  className="ios-download"
                  onClick={handleIosDownload}
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-7">
            <div className="text-container min-container">
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: 27,
                  fontFamily: "Helvetica",
                }}
                className="text-size"
              >
                Get the "Eat Protein" App for Android and iOS
              </span>
              <br />

              <p
                className="para-text-size"
                style={{
                  marginLeft: 20,
                  fontSize: 21,
                  fontFamily: "Helvetica",
                }}
              >
                Ready to take control of your nutrition? The "Eat Protein" app
                is your all-in-one guide to understanding the essential role of
                protein in your diet and finding the best sources, tailored to
                your needs.
              </p>

              <h4
                style={{
                  fontWeight: "bold",
                  fontSize: 28,
                  fontFamily: "Helvetica",
                }}
                className="text-size"
              >
                Why US Download?
              </h4>

              <ul
                className="para-text-size"
                style={{ fontSize: 21, fontFamily: "Helvetica", marginLeft: 0 }}
              >
                * Access nutritional details for proteins in various food
                categories like restaurants, groceries, meat, and dairy.
                <br />
                * Quickly find stores and restaurants that meet your protein
                needs, organized by location.
                <br />
                * Get tailored recommendations based on your diet and health
                goals.
                <br />
                * Find healthy protein options effortlessly with a user-friendly
                app.
                <br />* Use the app to stay on track with your fitness goals,
                whether it’s building muscle, losing weight, or maintaining a
                balanced diet.
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Download;
