import { useEffect } from "react";
import "./App.css";
import Head from "./Header";
function Privacy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* <Head /> */}
      <div className="container main-container" style={{ marginTop: 150 }}>
        <div className="row">
          <div className="col-12">
            <h1 className="policy-head">Privacy Policy</h1>
            <h4 className="policy-para">1. Introduction</h4>
            <p className="policy-para">
              This Privacy Policy ("Policy") describes how Mannschaft Infotech
              Private Limited ("Company") collects, uses, and discloses your
              personal information when you use our Website/mobile app,
              eatprotein.in ("Website/mobile app"). Please review this Policy
              carefully before using the Website/mobile app.
            </p>
            <h4 className="policy-para">2. Information collect</h4>
            <p className="policy-para">
              We collect various types of information from users of our
              Website/mobile app, including:
            </p>
            <ol className="terms policy-para">
              <li>
                <b>Personal Information:</b> This includes information that can
                be used to identify you, such as your name, email address,
                mailing address, phone number, and date of birth.
              </li>
              <li>
                <b>Device Information:</b> This includes information about your
                computer or mobile device, such as its IP address, operating
                system, browser type, and unique device identifiers.
              </li>
              <li>
                <b>Usage Information:</b> This includes information about how
                you use our Website/mobile app, such as the pages you visit, the
                products you view, and your search queries.
              </li>
              <li>
                <b>Payment Information:</b> This includes information used to
                process payments, such as your credit card number and billing
                address.
              </li>
            </ol>

            <div className="policy-para">
              <h4>3. How We Use Your Information</h4>
              <p>We use the information we collect to:</p>
              <ol class="terms policy-para">
                <li>Provide and operate the Website/mobile app.</li>
                <li>Process your orders and deliver your purchases.</li>
                <li>Communicate with you about your account and orders.</li>
                <li>
                  Send you marketing and promotional materials, with your
                  consent.
                </li>
                <li>Personalize your experience on the Website/mobile app.</li>
                <li>Analyze how users interact with the Website/mobile app.</li>
                <li>Improve the Website/mobile app and our services.</li>
                <li>Comply with legal and regulatory requirements.</li>
              </ol>
            </div>
            <div className="policy-para">
              <h4>4. Sharing Your Information</h4>
              <p>
                We may share your information with third-party service providers
                who help us operate the Website/mobile app and provide our
                services. We will only share your information with these
                providers to the extent necessary to perform their services and
                we require them to protect your information as we do.
              </p>
              <p>
                We may also disclose your information if required by law or to
                protect our rights or the rights of others.
              </p>
            </div>

            <div className="policy-para">
              <h4>5. Cookies and Other Tracking Technologies</h4>
              <p>
                We use cookies and other tracking technologies to collect
                information about your use of the Website/mobile app. Cookies
                are small data files that are stored on your device when you
                visit a Website/mobile app. You can configure your browser to
                refuse all cookies or to indicate when a cookie is being sent.
                However, if you do not accept cookies, you may not be able to
                use some portions of the Website/mobile app.
              </p>
            </div>
            <div className="policy-para">
              <h4>6. Data Security</h4>
              <p>
                We take reasonable precautions to protect your information from
                unauthorized access, disclosure, alteration, or destruction.
                However, no Website/mobile app or internet transmission is
                completely secure, and we cannot guarantee the security of your
                information.
              </p>
            </div>
            <div className="policy-para">
              <h4>7. Your Choices</h4>
              <p>You have choices regarding your information:</p>
              <ol class="terms policy-para">
                <li>You can choose not to provide certain information.</li>
                <li>
                  You can opt out of receiving marketing and promotional emails
                  from us.
                </li>
                <li>You can access and update your personal information.</li>
                <li>
                  You can request that we delete your personal information.
                </li>
              </ol>
            </div>
            <div className="policy-para">
              <h4>8. Children's Privacy</h4>
              <p>
                Our Website/mobile app is not intended for children under the
                age of 13. We do not knowingly collect personal information from
                children under the age of 13.
              </p>
            </div>
            <div className="policy-para">
              <h4>9. Changes to This Policy</h4>
              <p>
                We may update this Policy from time to time. We will notify you
                of any changes by posting the new Policy on this page.
              </p>
            </div>
            <div className="policy-para">
              <h4>10. Contact Us</h4>
              <p>
                If you have any questions about this Policy, please contact us
                at support@eatprotein.in or +91 7036356760
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Privacy;
