import { Link } from "react-router-dom";
import "./App.css";
import { useState } from "react";
function About() {
  const [result, setResult] = useState([]);
  const [show, setShow] = useState(false);
  const whyusText =
    "Discover the importance of protein in your diet with our “Eat Protein” app, designed to help you make informed choices about your nutrition. Explore different categories of protein, including plant-based, animal-based, and protein supplements, each tailored to meet your specific dietary needs. Whether you’re looking to build muscle, maintain a balanced diet, or improve your overall health, our app provides detailed information on why protein is crucial.Easily find nearby stores and online retailers offering a wide range of protein products. The app uses location-based services to list the best protein sources available in your area, from grocery stores to specialty health food shops. With user-friendly navigation, you can quickly compare products, check prices, and read reviews to make the best choice for your health goals.Stay informed, shop smart, and fuel your body with the right proteins wherever you are!";

  const handleToggle = () => {
    setShow(!show);
  };
  function isExpanded(text, maxlength) {
    const previewText = text.slice(0, maxlength);
    const isLongText = text.length > maxlength;
    return (
      <div>
        <p className="para-text-size" style={{ alignContent: "center" }}>
          {show || !isLongText ? text : `${previewText}...`}
        </p>
        {isLongText && (
          <Link onClick={handleToggle}>{show ? "Read Less" : "Read More"}</Link>
        )}
      </div>
    );
  }
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <h1 className="about-head col-12">Why US </h1>

          <div className="col-12 d-lg-none">
            <div className="image-container min-container">
              <img
                src={"/Download app.png"}
                className="aboutus-image shadow-lg"
                alt=""
              />
            </div>
          </div>
          <div className="col-12 d-block d-lg-none">
            <div className="text-container min-container para-text-size">
              {isExpanded(whyusText, 295)}
            </div>
          </div>
          <div className="col-12 col-lg-7 d-none d-lg-block">
            <div className=" d-flex flex-column justify-content-center h-100">
              <div className="text-container min-container para-text-size">
                <p
                  className="para-text-size"
                  style={{ alignContent: "center" }}
                >
                  Discover the importance of protein in your diet with our “Eat
                  Protein” app, designed to help you make informed choices about
                  your nutrition. Explore different categories of protein,
                  including plant-based, animal-based, and protein supplements,
                  each tailored to meet your specific dietary needs. Whether
                  you’re looking to build muscle, maintain a balanced diet, or
                  improve your overall health, our app provides detailed
                  information on why protein is crucial.Easily find nearby
                  stores and online retailers offering a wide range of protein
                  products. The app uses location-based services to list the
                  best protein sources available in your area, from grocery
                  stores to specialty health food shops. With user-friendly
                  navigation, you can quickly compare products, check prices,
                  and read reviews to make the best choice for your health
                  goals.Stay informed, shop smart, and fuel your body with the
                  right proteins wherever you are!
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 d-none d-lg-block col-lg-5">
            <div className="image-container min-container">
              <img
                src={"/Download app.png"}
                className="aboutus-image shadow-lg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default About;
