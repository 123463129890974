import { useEffect } from "react";
import "./App.css";
import Head from "./Header";
function Shipping() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* <Head /> */}
      <div className="container main-container" style={{ marginTop: 150 }}>
        <div className="row">
          <div className="col-12">
            <h1 className="policy-head">Shipping Policy</h1>
            <h4 className="policy-headh4">1. Introduction</h4>
            <p className="policy-para">
              This Shipping Policy ("Policy") describes the shipping methods,
              costs, and delivery times offered by Mannschaft Software Ser
              ("Company") for orders placed on our website,
              shop.mannschaftit.com Please review this Policy carefully before
              placing your order.
            </p>
            <h4 className="policy-headh4">2. Shipping Methods</h4>
            <p className="policy-para">
              We offer the following shipping methods:
            </p>
            <ol className="terms policy-para">
              <li>
                Standard Shipping: 1 Working Days and Cost Based on Package Size
                and Dimension, Location.
              </li>
              <li>
                Expedited Shipping: 4 Hrs Time and Cost Based on Package Size
                and Dimension, Location
              </li>
            </ol>
            <div className="policy-para">
              <h4>3. Shipping Costs</h4>
              <p>Shipping costs are calculated based on:</p>
              <ul className="terms">
                <li>
                  Order weight and dimensions: Depends on the Product which u
                  selected.
                </li>
                <li>Delivery destination: Location distance based pricing</li>
                <li>Chosen shipping method: [Refer back to section 2].</li>
              </ul>
              <p>
                Shipping costs will be displayed during checkout before you
                complete your order.
              </p>
            </div>
            <div className="policy-para">
              <h4>4. Delivery Times</h4>
              <p>
                Delivery times are estimated based on the chosen shipping method
                and your location. We will provide an estimated delivery date
                during checkout and a tracking number once your order ships.
                Please note that estimated delivery times are not guaranteed and
                may be impacted by unforeseen circumstances.
              </p>
            </div>
            <div className="policy-para">
              <h4>5. Order Processing Time</h4>
              <p>
                Orders are typically processed within 1 business day after being
                placed. Please note that processing times may vary depending on
                the volume of orders or product availability.
              </p>
            </div>
            <div className="policy-para">
              <h4>6. Shipping Restrictions</h4>
              <p>
                We may have shipping restrictions for certain items or
                locations. We will notify you of any restrictions during
                checkout.
              </p>
            </div>
            <div className="policy-para">
              <h4>7. International Shipping</h4>
              <p>We are not offering international shipping to any country. </p>
            </div>
            <div className="policy-para">
              <h4>8. Risk of Loss</h4>
              <p>
                All items purchased from our website/mobile app are shipped at
                your risk. This means that you are responsible for any loss or
                damage to the items during transit. We recommend purchasing
                shipping insurance to protect your order.
              </p>
            </div>
            <div className="policy-para">
              <h4>9. Returns and Refunds</h4>
              <p>
                Our return and refund policy is described in a separate document
                available on our website/mobile app. Please refer to that
                document for information on how to return an item and receive a
                refund.
              </p>
            </div>
            <div className="policy-para">
              <h4>10. Changes to Shipping Policy</h4>
              <p>
                We reserve the right to change this Policy at any time. We will
                post any changes on our website/mobile app.
              </p>
            </div>
            <div className="policy-para">
              <h4>11. Contact Information</h4>
              <p>
                If you have any questions about this Shipping Policy, please
                contact us at support@eatprotein.in.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Shipping;
