import { useEffect } from "react";
import "./App.css";
import Head from "./Header";
function Terms() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Head />
      <div className="container main-container" style={{ marginTop: 150 }}>
        <div className="row">
          <div className="col-12">
            <h1 className="policy-head">Terms & Conditions</h1>
            <h4 className="policy-para"> Introduction</h4>
            <p className="policy-para">
              This Terms and Conditions Agreement ("Agreement") governs your use
              of the website and mobile app eatprotein.in ("Website/mobile app")
              operated by Mannschaft Software Services LLP ("Company"). By
              accessing or using the Website/mobile app, you agree to be bound
              by the terms and conditions set forth in this Agreement. If you
              disagree with any of these terms, you are prohibited from using
              the Website/mobile app.
            </p>
            <h4 className="policy-para">Definitions</h4>
            <ol className="policy-para terms">
              <li>
                "User" means you, the person accessing or using the
                Website/mobile app.
              </li>
              <li>
                "Content" means all text, graphics, images, video, audio, data,
                and other information available on the Website/mobile app.
              </li>
              "Order" means an electronic request by you to purchase a product
              or service offered on the Website/mobile app.
              <li>
                "Product" means any item offered for sale on the Website/mobile
                app.
              </li>
              <li>
                "Service" means any service offered on the Website/mobile app.
              </li>
            </ol>

            <div className="policy-para">
              <h4>User Accounts</h4>
              <p>
                You may create an account on the Website/mobile app to access
                certain features and benefits. You are responsible for
                maintaining the confidentiality of your account information,
                including your password, and for all activities that occur under
                your account. You agree to notify the Company immediately of any
                unauthorized use of your account or any other security breach.
              </p>
            </div>
            <div className="policy-para">
              <h4>Ordering and payment</h4>
              <p>
                To place an Order, you must follow the online ordering process
                on the Website. You agree to provide accurate and complete
                information during the ordering process. Payment for Orders may
                be made through the payment methods offered on the
                Website/mobile app. Company reserves the right to refuse any
                Order at its sole discretion.
              </p>
            </div>
            <div className="policy-para">
              <h4>Shipping and Delivery</h4>
              <p>
                Company will ship Orders to the address you provide during the
                ordering process. Shipping and delivery costs and estimated
                delivery times will be displayed during the checkout process.
                Company is not responsible for delays in shipping or delivery
                due to events beyond its control.
              </p>
            </div>
            <div className="policy-para">
              <h4>Returns and Refunds</h4>
              <p>
                Company offers a describe your return and refund policy,
                including return window, accepted return conditions, and refund
                processing details
              </p>
            </div>
            <div className="policy-para">
              <h4>Content and intellectual property</h4>
              <p>
                All Content on the Website is the property of Company or its
                licensors and is protected by copyright and other intellectual
                property laws. You may not modify, copy, distribute, transmit,
                display, perform, reproduce, publish, license, create derivative
                works from, transfer, or sell any Content, except as expressly
                provided in this Agreement.
              </p>
            </div>
            <div className="policy-para">
              <h4>User Conduct</h4>
              <p>
                You agree to use the Website in accordance with all applicable
                laws and regulations. You agree not to:
              </p>
              <ol className="policy-para terms">
                <li>
                  Use the Website/mobile app for any illegal or unauthorized
                  purpose.
                </li>
                <li>Interfere with or disrupt the Website or its servers</li>
                <li>Transmit any viruses, worms, or other harmful code.</li>
                <li>
                  Violate the privacy or intellectual property rights of others.
                </li>
                <li>
                  Engage in any defamatory, obscene, or threatening behavior.
                </li>
              </ol>
            </div>
            <div className="policy-para">
              <h4>Disclaimer of Warranties</h4>
              <p>
                The Website and its Content are provided "as is" and without
                warranties of any kind, express or implied. Company disclaims
                all warranties, express or implied, including, but not limited
                to, the implied warranties of merchantability, fitness for a
                particular purpose, and non-infringement. Company does not
                warrant that the Website will be error-free, uninterrupted, or
                virus-free.
              </p>
            </div>
            <div className="policy-para">
              <h4>Limitation of Liability</h4>
              <p>
                Company shall not be liable for any damages of any kind arising
                from the use of the Website/mobile app, including, but not
                limited to, direct, indirect, incidental, punitive, and
                consequential damages.
              </p>
            </div>
            <div className="policy-para">
              <h4>Term and Termination</h4>
              <p>
                This Agreement shall remain in effect until terminated by either
                party. Company may terminate this Agreement at any time without
                notice. You may terminate this Agreement by stopping your use of
                the Website/mobile app.
              </p>
            </div>
            <div className="policy-para">
              <h4>Governing Law and Disputes</h4>
              <p>
                This Agreement shall be governed by and construed in accordance
                with the laws of [state/country]. Any dispute arising out of or
                relating to this Agreement shall be resolved in the
                [court/arbitration] of [state/country
              </p>
            </div>
            <div className="policy-para">
              <h4>Changes to Terms and Conditions</h4>
              <p>
                Company may revise this Agreement at any time by updating this
                posting. You are bound by any revisions to this Agreement by
                using the Website/mobile app after such revisions are posted.
              </p>
            </div>
            <div className="policy-para">
              <h4>Contact Information </h4>
              <p> You may contact Company at support@eatprotein.in</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Terms;
