import "./App.css";
import { Link, NavLink } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { useState } from "react";

function Head() {
  const selectedColor = "#FF44EE";
  const textColor = "#0000A6";
  const [toggleStatus, setToggleStatus] = useState(false);

  return (
    <>
      <div className="container-fluid pageMinHeight header-fixed naving">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <Navbar expand="lg" collapseOnSelect={true}>
                <Navbar.Brand href="/#home" className="w-25">
                  <Link to="/" className="home-nav">
                    {" "}
                    <img src={"/eat logo.png"} className="logo-img" alt="" />
                  </Link>
                </Navbar.Brand>

                <Navbar.Toggle aria-controls="basic-navbar-nav " />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="margin-nav set-width">
                    <Nav.Link href="/#home" className="nav-design">
                      Home
                    </Nav.Link>
                    <Nav.Link href="/#aboutUs" className="nav-design">
                      Why Us
                    </Nav.Link>
                    <Nav.Link href="/#download" className="nav-design">
                      Download App
                    </Nav.Link>
                    <Nav.Link href="/#referAFriend" className="nav-design">
                      Refer A Friend
                    </Nav.Link>
                    <Nav.Link href="/#services" className="nav-design">
                      Franchisee
                    </Nav.Link>
                    <Nav.Link
                      href="/#footer"
                      className="nav-design nav-design2"
                    >
                      Contact Us
                    </Nav.Link>
                  </Nav>
                  {/* <nav className="margin-nav set-width">
        <ul className='remove-ul d-flex flex-row'>
          <li>
            <NavLink
              to={"/"}
              className="home-nav nav-design"
              style={({ isActive }) => ({
                color: isActive ? selectedColor : textColor,
              })}
            >
              Home
            </NavLink>
          </li>
          {/* <li>
            <NavLink
              to={"/about"}
              style={({ isActive }) => ({
                color: isActive ? selectedColor : textColor,
              })}
            >
             Why Us
            </NavLink>
          </li> */}
                  {/* <li>
            <NavLink
              to={"/download"}
              className="home-nav nav-design"
              style={({ isActive }) => ({
                color: isActive ? selectedColor : textColor,
              })}
            >
              Download
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/referAndSave"}
              className="home-nav nav-design"
              style={({ isActive }) => ({
                color: isActive ? selectedColor : textColor,
              })}
            >
              Refer A Friend
            </NavLink>
          </li>
          <li>
            <NavLink
             className="home-nav nav-design"
              to={"/franchise"}
              style={({ isActive }) => ({
                color: isActive ? selectedColor : textColor,
              })}
            >
              Franchisee
            </NavLink> */}
                  {/* </li> */}
                  {/* <li>
            <NavLink
              to={"/contact"}
              style={({ isActive }) => ({
                color: isActive ? selectedColor : textColor,
              })}
            >
              Contact Us
            </NavLink>
          </li> */}
                  {/* </ul>
      </nav> */}
                </Navbar.Collapse>
              </Navbar>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Head;
