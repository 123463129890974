import { Link } from "react-router-dom";
import "./App.css";
import { useEffect, useState } from "react";
import Head from "./Header";
function Service() {
  const [show, setShow] = useState(false);
  const franchiseText =
    'Are you searching for a way to generate additional income with minimal investment and effort? We are excited topresent a unique franchising opportunity with the "Eat Protein" mobile app – a cutting-edge platform that delivers detailed protein nutrition information across various food categories, including restaurants, groceries, meat, dairy,and more. With convenient location-based store listings, this app is designed to help users make informed dietary choices while supporting their health and wellness goals.';
  const earning =
    'If you’re ready to explore this exciting opportunity and take the next step towardsbecoming an "Eat Protein" franchise owner, contact us today. Let’s build a healthier future together!';

  const handleToggle = () => {
    setShow(!show);
  };
  function isExpanded(text, maxlength) {
    const previewText = text.slice(0, maxlength);
    const isLongText = text.length > maxlength;
    return (
      <>
        <div>
          <span className="para-size">
            {show || !isLongText ? text : `${previewText}...`}
          </span>
          {isLongText && (
            <Link style={{ marginLeft: 25 }} onClick={handleToggle}>
              {show ? "Read Less" : "Read More"}
            </Link>
          )}
        </div>
      </>
    );
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* <Head /> */}
      <div className="container aboutPage " style={{ marginTop: 150 }}>
        <div className="row">
          <div className="col-12 p-0 ">
            <h2 className="font-bold text-alignment mb-5">
              Bring "Eat Protein" to Your Community
            </h2>
          </div>

          <div className="col-12 d-none d-lg-block  d-flex flex-row  para-size">
            Are you searching for a way to generate additional income with
            minimal investment and effort? We are excited to present a unique
            franchising opportunity with the "Eat Protein" mobile app – a
            cutting-edge platform that delivers d etailed protein nutrition
            information across various food categories, including restaurants,
            groceries, meat, dairy, and more. With convenient location-based
            store listings, this app is designed to help users make informed
            dietary choices while supporting their health and wellness goals.
          </div>
          <div className="col-12 d-block d-lg-none  d-flex flex-row pl-5 para-size">
            <ul>{isExpanded(franchiseText, 186)}</ul>
          </div>
          <div className="col-12">
            <h3 className="font-bold text-alignment">
              Why Choose "Eat Protein"?
            </h3>
          </div>

          <div className="col-12 d-lg-none display-image">
            <div className="image-container min-container">
              <img
                src={"/franchise .png"}
                className="aboutus-image shadow-lg service-height"
                alt=""
              />
            </div>
          </div>

          <div className="col-12 col-lg-7 mt-3">
            <ul className="remove-ul">
              <li className="para-size">
                <span className="mini-font-bold">
                  * Minimal Investment, Maximum Return:
                </span>{" "}
                Start your franchise journey with a low upfront cost and benefit
                from a business model designed to maximize profitability. This
                opportunity offers you a way to earn significant returns without
                requiring a large financial commitment.
              </li>
              <li className="para-size">
                <span className="mini-font-bold">
                  {" "}
                  * Effortless Income Stream:
                </span>{" "}
                With the "Eat Protein" franchise, you can generate a steady
                income with minimal daily involvement. The app is designed to
                operate efficiently with little oversight, allowing you to enjoy
                passive income while focusing on other aspects of your life.
              </li>
              <li className="para-size">
                <span className="mini-font-bold">
                  {" "}
                  * No Disruption to Your Current Profession:{" "}
                </span>
                One of the key advantages of this opportunity is that it doesn’t
                require you to leave your current job. You can maintain your
                existing career while reaping the financial rewards from this
                additional business venture.
              </li>
              <li className="para-size">
                <span className="mini-font-bold">
                  * Ready-to-Launch Solution:
                </span>{" "}
                We provide a fully developed, ready-to-use mobile app, allowing
                you to start your business immediately. There’s no need to worry
                about the complexities of development – everything is prepared
                for you to hit the ground running.
              </li>
            </ul>
          </div>

          <div className="d-none d-lg-block col-lg-5 ">
            <div className="image-container min-container">
              <img
                src={"/franchise .png"}
                className="aboutus-image shadow-lg service-height"
                alt=""
              />
            </div>
          </div>

          <div className="col-12">
            <h3 className="font-bold text-alignment">What We Offer</h3>
          </div>

          <div className="col-12 col-lg-5 d-flex flex-row justify-content-center">
            <div className="image-container min-container">
              <img
                src={"/franchise2.png"}
                style={{ width: 350 }}
                className="aboutus-image shadow-lg"
                alt=""
              />
            </div>
          </div>

          <div className="col-12 col-lg-7">
            <ul className="remove-ul">
              <li className="para-size">
                <span className="mini-font-bold">
                  * Comprehensive Nutrition Information:
                </span>
                Our app is a valuable resource for health-conscious consumers
                who are looking for reliable, detailed protein-related data.
                Whether it’s for building muscle, managing weight, or
                maintaining a balanced diet, "Eat Protein" helps users make the
                best dietary choices.
              </li>

              <li className="para-size">
                <span className="mini-font-bold">
                  * User-Friendly Store Listings:
                </span>{" "}
                The app features intuitive, location-based store listings that
                guide users to the best places to shop, eat, and stay healthy in
                their area. This feature enhances the user experience, making it
                easy for customers to find what they need quickly and
                conveniently.
              </li>
              <li className="para-size">
                <span className="mini-font-bold">
                  {" "}
                  * Turnkey Business Solution:{" "}
                </span>{" "}
                We provide everything you need to get started, including
                marketing materials, training, and ongoing support. Our turnkey
                solution ensures a smooth and easy launch, allowing you to focus
                on growing your business from day one.
              </li>
              <li className="para-size">
                <span className="mini-font-bold">
                  * Brand Support and Growth Opportunities:
                </span>
                As a franchise partner, you will benefit from the "Eat Protein"
                brand’s growing recognition and reputation. We offer ongoing
                support to help you expand your reach and enhance your business
                success.
              </li>
              <li className="para-size">
                <span className="mini-font-bold">
                  * Exclusive Territory Rights:
                </span>
                Secure exclusive rights to operate the "Eat Protein" franchise
                within your designated area. This ensures that you have the
                opportunity to dominate the local market without competition
                from other franchisees.
              </li>
              <li className="para-size">
                <span className="mini-font-bold">
                  * Scalable Business Model:
                </span>
                The "Eat Protein" franchise offers a scalable business model,
                allowing you to expand your operations as your business grows.
                You can start with a single location and gradually increase your
                footprint to cover more areas, multiplying your revenue
                potential.
              </li>
            </ul>
          </div>
          <div className="col-12">
            <h3 className="font-bold text-alignment mb-2">
              Make a Positive Impact While Earning a Steady Income
            </h3>
          </div>

          <div className="col-12 d-none d-lg-block">
            <p className="para-size">
              This is more than just a business opportunity – a chance to make a
              meaningful impact in your community by promoting healthier living
              through informed dietary choices. By becoming a franchise partner,
              you’ll secure a reliable income stream and contribute to your
              customers' well-being.
            </p>
            <p className="para-size">
              If you’re ready to explore this exciting opportunity and take the
              next step towards becoming an "Eat Protein" franchise owner,
              contact us today. Let’s build a healthier future together!
            </p>
          </div>
          <div className="col-12 d-block d-lg-none">
            <ul className="para-size">
              This is more than just a business opportunity – a chance to make a
              meaningful impact in your community by promoting healthier living
              through informed dietary choices. By becoming a franchise partner,
              you’ll secure a reliable income stream and contribute to your
              customers well-being. <br />
              {isExpanded(earning, 0)}
            </ul>
            {/* <p className='para-size'>
                            If you’re ready to explore this exciting opportunity and take the next step towards
                            becoming an "Eat Protein" franchise owner, contact us today. Let’s build a healthier future together!

                        </p> */}
          </div>
        </div>
      </div>
    </>
  );
}
export default Service;
