import axios from "axios";
import { useEffect, useState } from "react";
import { API_CONFIG, API_HEADERS } from "./Api/API_CONFIG";
import Carousel from "react-bootstrap/Carousel";
import About from "./AboutUS";
import ReferAndSave from "./refer-save";

function Slider() {
  const [result, setResult] = useState([]);
  useEffect(() => {
    fetchImages();
  }, []);
  const fetchImages = async () => {
    let data = await axios.get(API_CONFIG.API_HOST + "Banner/list?type=HOME", {
      headers: API_HEADERS,
    });
    console.log(data.data.result);
    setResult(data.data.result);
  };
  return (
    <>
      <div>
        <Carousel fade className="carousel-image">
          {result &&
            result.length > 0 &&
            result.map((item) => {
              return (
                <Carousel.Item className="d-flex flex-row justify-content-center">
                  <img
                    className="w-100 image-height"
                    src={item.image_path}
                    alt="First slide"
                  />
                </Carousel.Item>
              );
            })}
        </Carousel>
      </div>
    </>
  );
}
export default Slider;
