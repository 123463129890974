export const IS_PRODUCTION = false;

export const API_CONFIG = {
    API_HOST: getApiHost(),
    ACCESS_KEY: "A31AB78E-C4C7-4C9E-AD98-6D6A1B801E45",
  };
  
  /**
   * Requst Headers
   */
  export const API_HEADERS = {
    "Content-Type": "application/json",
    "api-access-key": API_CONFIG.ACCESS_KEY,
  };
  
  function getApiHost() {
    return IS_PRODUCTION
      ? "https://api.eatprotein.in/"
      : "https://cms.eatprotein.in/";
  }